import moment from 'moment';
import { dateFormat } from './constants';
import { dateFormatISO } from 'utils/constants';

export const setFormatDateTime = (dateTime, index, withM) => {
  // console.log('dateTime', dateTime);
  // console.log('index', index);
  // console.log('withMileseconds :>> ', withM);
  if (typeof dateTime !== 'undefined' && dateTime) {
    let d = dateTime.split('T');
    if (d.length > 1) {
      if (index === 1) {
        let a = d[index].split(':');
        if (withM && a[2]) {
          let removeIso = a[2].split('.');
          if (removeIso && removeIso.length > 0) {
            return `${a[0]}:${a[1]}:${removeIso[0]}`;
          } else {
            return `${a[0]}:${a[1]}:${a[2]}`;
          }
        } else {
          return `${a[0]}:${a[1]}`;
        }
      }
      const preResult = d[index];
      return moment(preResult).format(dateFormat);
    } else if (dateTime.includes('.')) {
      const hour = dateTime.split('.');
      if (hour[0]) {
        return hour[0];
      }
    } else {
      return dateTime;
    }
  } else {
    return '- - : - - ';
  }
};

export const formatDateToISO = value => {
  let formatedDate;
  if (value === undefined || value === null) {
    formatedDate = undefined;
  } else {
    formatedDate = moment(value).format(dateFormatISO);
  }
  return formatedDate;
};

export const formatHourHHMMSS = (value, withOutAMPM) => {
  let formatedHour;
  if (value === undefined || value === null) {
    formatedHour = undefined;
  } else {
    const format = withOutAMPM === true ? 'HH:mm:ss' : 'hh:mm:ss A';
    formatedHour = moment(value).format(format);
  }
  return formatedHour;
};

export const formatDateDDMMYYYY = value => {
  let formatedDate;
  if (value === undefined || value === null) {
    formatedDate = undefined;
  } else {
    let isValid = moment(value, 'DD/MM/YYYY').isValid();
    if (isValid) {
      formatedDate = moment(value, 'DD/MM/YYYY').format(dateFormat);
    } else {
      formatedDate = moment(value).format(dateFormat);
    }
  }
  return formatedDate;
};

export const formatDateDDMMYYYYHHSS = value => {
  let formatedDateHour;
  if (value === undefined || value === null) {
    formatedDateHour = undefined;
  } else {
    let isValid = moment(value, 'DD/MM/YYYY [-] HH:mm').isValid();

    if (isValid) {
      formatedDateHour = moment(value, 'DD/MM/YYYY [-] HH:mm').format(dateFormat);
    } else {
      formatedDateHour = moment(value).format('DD/MM/YYYY [-] HH:mm');
    }
  }
  return formatedDateHour;
};
