import CryptoJS from 'crypto-js/crypto-js';

export const encryptAes = (text) => {

    let keyInitial = process.env.REACT_APP_KEY_INITIAL;

    let ivInitial = process.env.REACT_APP_IV_INITIAL;

    let key = CryptoJS.enc.Utf8.parse(keyInitial);

    let iv = CryptoJS.enc.Utf8.parse(ivInitial);

    return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();
}