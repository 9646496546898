import i18next from 'i18next';

export const companyName = `AUTOONLINE`;
export const companyURL = `www.autoonline.com.mx`;
export const companyLegalName = `AUTOONLINE, S. DE R.L. DE C.V. (“AUTOONLINE”)`;
/* Routes */
export const mainHome = '/dashboard';
export const catalogsDetails = '/catalogs/details';
export const toAddMassivePhotos = '/cases/add/photos';
export const toCasesSearch = '/cases/search';
export const toUsersSearch = '/users/search/';
export const toUniversePT = '/cases/universept';
export const toUserDetail = '/users/admin/detail/';
export const toLogin = '/login';
export const toTransfersSearch = '/transfers/search';
export const toAdvertsSearch = '/adverts/search';
export const toAwardingsSearch = '/awardings/search/1';
export const toInitialPaymentSearch = '/payments/search/initial'
/* Labels */
export const labelButtonNext = 'Continuar';
export const labelButtonBack = 'Regresar';
export const labelButtonCancel = 'Cancelar';
export const labelButtonAccept = 'Aceptar';
export const labelButtonAwait = 'Espere...';
export const labelButtonBeginTransfer = 'Iniciar traslado';
export const labelButtonUpdate = 'Actualizar';
export const labelButtonRegistrar = 'Aceptar';
export const dateFormat = 'DD/MM/YYYY';
export const yearFormat = 'YYYY';
export const dateFormatISO = 'YYYY-MM-DD';
export const hourFormat = 'HH:mm:ss';
export const phoneFormat = '9999999999';

export const headerBase64JPG = 'data:image/jpeg;base64,';
export const headerBase64PNG = 'data:image/png;base64,';
export const headerBase64PDF = 'data:application/pdf;base64,';
export const headerBase64DOC = 'data:application/msword;base64,';
export const headerBase64PPT = 'data:application/vnd.ms-powerpoint;base64,';
export const headerBase64MDB = 'data:application/msaccess;base64,';
export const headerBase64PUB = 'data:application/vnd.ms-publisher;base64,';
export const headerBase64DOCX = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
export const headerBase64PPTX = 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,';
export const headerBase64ACCDB = 'data:application/vnd.ms-access;base64,';
export const headerBase64XLS = 'data:application/vnd.ms-excel;base64,';
export const headerBase64XLSX = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';

/*  Valid File Types */
export const validXLSFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
export const validZipFiles = [
  'application / zip',
  'application/x-zip-compressed',
  'multipart / x - zip'
];

export const validPDFFiles = ['application/pdf'];
export const validImageFiles = ['image/png', 'image/jpeg'];
export const validSLSXExtension = ['xlsx', 'xls'];
export const validPowerPointExtensions = ['ppt', 'pptx'];
export const validAccessExtensions = ['mdb', 'accdb'];
export const validPublisherExtensions = ['pub'];
export const validWordExtensions = ['doc', 'docx']; 
export const validImageFileExtensions = ['jpeg', 'jpg', 'png'];
export const validFilesExtensions = ['jpeg', 'jpg', 'png', 'pdf'];
export const validPdfExtensions = ['pdf'];
export const validVouchers = ['image/png', 'image/jpeg', 'application/pdf'];
export const validFormatAttachmentsDocuments = 'PDF,JPG o PNG';
export const validFormatAttachmentsPhotos = 'JPG o PNG';
export const validPowerPointFiles = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint'
];
export const validAccessFiles = [
  'application/msaccess'
];
export const validPublisherFiles = [
  'application/vnd.ms-publisher'
];
export const validWordFiles = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword'
];

export const roleIdInt = '65656556';
export const roleIdMas = '998898998';

export const paymentAutoonline = {
  bankName: 'BBVA Bancomer, S.A',
  clabeAccount: '012180 001672 051200',
  account: '0167205120',
  cieNumber: '1078828'
};

export const casesReports = 'Cases/Api/v1/Reports/';
export const buyerReports = 'Buyers/Api/v1/Reports/';
