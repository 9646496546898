import i18next from 'i18next';
import moment from 'moment';
import { messageService } from 'services/message';
const DIGITS = 'dígitos';
const CHARS = 'caracteres';

// export const hasErrors = fieldsError => {
//   return Object.keys(fieldsError).some(field => fieldsError[field]);
// };

export const disabledPastDays = current => {
  return current && current < moment().add(-1, 'days');
};

export const disabledFutureDays = current => {
  return current && current > moment().add(-1, 'days');
};

export const disabledExactFutureDays = current => {
  return current && current > moment();
};

export const disabledFutureDaysFromTomorrow = current => {
  return current && current > moment().add(0, 'days');
};

export const setRequiredField = fieldName => {
  return `${i18next.t('system.filedtext')} ${fieldName} ${i18next.t('system.filedrequirement')}`;
};

export const setInvalidEmail = () => {
  return `${i18next.t('system.emailvalidation')}`;
};

export const setInvalidPattern = fieldName => {
  return `El campo ${fieldName} no cumple con el formato requerido`;
};

export const setMinLengthCharacters = (min, isNumber) => {
  let typeMessage = typeof isNumber === 'undefined' ? DIGITS : CHARS;
  return `Este campo debe tener al menos ${min} ${typeMessage}.`;
};

export const setMaxLengthCharacters = (max, isNumber) => {
  let typeMessage = typeof isNumber === 'undefined' ? DIGITS : CHARS;
  return `Este campo debe tener máximo ${max} ${typeMessage}.`;
};

export const setMaxLengthDigits = max => {
  return `Este campo debe tener máximo ${max} dígitos.`;
};

export const setMinLengthDigits = min => {
  return `Este campo debe tener minimo ${min} dígitos.`;
};

export const setRuleRequired = (nameField, type) => {
  if (typeof type !== 'undefined') {
    return { type, required: true, message: setRequiredField(nameField) };
  } else {
    return { required: true, message: setRequiredField(nameField) };
  }
};

export const setRuleMin = (minLength, isNumber) => {
  let existNumber = typeof isNumber !== 'undefined' ? true : false;
  return { min: minLength, message: setMinLengthCharacters(minLength, existNumber) };
};

export const setRuleMax = (maxLength, isNumber) => {
  let existNumber = typeof isNumber !== 'undefined' ? true : false;
  return { max: maxLength, message: setMaxLengthCharacters(maxLength, existNumber) };
};

export const setRuleMaxNumber = maxLength => {
  return { max: maxLength, message: setMaxLengthDigits(maxLength) };
};

export const setRuleMinNumber = minLength => {
  return { min: minLength, message: setMinLengthCharacters(minLength) };
};

export const setRuleEmail = () => {
  return {
    message: setInvalidEmail(),
    type: 'email'
  };
};

export const validateCURP = CURP => {
  // const reCURP = new RegExp(
  //   /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/i
  // );
  // const reCURP = new RegExp(
  //   /^([A-Z][AEIOUX][A-Z][A-Z]\d\d(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z][B-DF-HJ-NP-TV-Z][B-DF-HJ-NP-TV-Z][A-Z\d])(\d)$/i
  // );

  const reCURP = new RegExp(
    /^([A-Z][AEIOUX][A-Z][A-Z]\d\d(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/i
  );

  // ^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$
  var validado = CURP.match(reCURP, '$1');
  if (!validado) return false;

  // function digitoVerificador(curp17) {
  //   let diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
  //   let lngSuma = 0.0;
  //   for (var i = 0; i < 17; i++) {
  //     lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
  //   }
  //   let lngDigito = 10 - (lngSuma % 10);
  //   if (lngDigito === 10) {
  //     return 0;
  //   }
  //   return lngDigito;
  // }

  // if (+validado[2] !== digitoVerificador(validado[1])) return false;

  return true;
};

export const validateRFCPersonal = RFC => {
  let isValid = false;
  const reRFC = new RegExp(
    /(^([A-ZÑ&][A-ZÑ&][A-ZÑ&][A-ZÑ&])(\d\d(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))([A-Z\d][A-Z\d])([A\d])$)/gi
  );

  isValid = RFC.match(reRFC, '$1');
  return isValid;
};

export const validateRFCMoral = RFC => {
  let isValid = false;
  const reRFC = /^[A-Z&Ñ]{3}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z\d]{2}[0-9A]$/;
  isValid = reRFC.test(RFC);
  return isValid;
};

export const validateNewPassword = value => {
  let isValid = false;
  const validPassword = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /(^(?=.*\d)(?=.*[@,-,#,$,%,&,\!])(?=.*[A-Z])(?=.*[a-z])\S{8,16}$)/
  );
  isValid = validPassword.test(value);
  return isValid;
};

export const validatePassword = value => {
  let isValid = false;
  isValid = value;
  return isValid;
};

export const validateRFC = RFC => {
  let isValid = false;
 const re       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var   validado = RFC.match(re);
  isValid = validateRFCPhysical(validado);
  return isValid;
};

export const validateRFCPhysical = RFC => {

  if (!RFC)  //Coincide con el formato general del regex?
        return false;

  const digitoVerificador = RFC.pop(),
          rfcSinDigito      = RFC.slice(1).join(''),
          len               = rfcSinDigito.length,

    //Obtener el digito esperado
          diccionario       = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
          indice            = len + 1;
    var   suma,
          digitoEsperado;

    if (len == 12) suma = 0;

    for(var i=0; i<len; i++)
        suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - suma % 11;
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";

    //El dígito verificador coincide con el esperado?
    // o es un RFC Genérico (ventas a público general)?
    if (digitoVerificador != digitoEsperado)
        return false;
        
    return rfcSinDigito + digitoVerificador;
};

export const validateNumber = number => {
  let isValid = false;
  let patternNumber = new RegExp(/^\d+$/);
  if (number) {
    // isValid = number.match(patternNumber, '$1');
    isValid = patternNumber.test(number);

    return isValid;
  }
};

export const validateClabe = string => {
  let isValid = false;
  if (typeof string === 'string') {
    if (string.length === 16 || string.length === 18) {
      isValid = true;
    }
  }
  return isValid;
};

export const setInvalidFormat = (type, fieldName, value, cb, compareToValue, equalPass) => {
  let isValid = false;
  let finalMessage = `El campo ${fieldName} no tiene un formato válido`;
  if (typeof value !== 'undefined' && value !== '') {
    try {
      if (type === 'CURP') {
        isValid = validateCURP(value);
        finalMessage = i18next.t('MSG_030');
      }
      if (type === 'RFCPERSONAL') {
        isValid = validateRFCPersonal(value);
        if (!isValid) {
          finalMessage = i18next.t('MSG_031_A');
        }
      }
      if (type === 'RFC') {
        isValid = validateRFC(value);
        if (!isValid) {
          finalMessage = i18next.t('MSG_031_A');
        }
      }

      if (type === 'NEW_PASSWORD') {
        const equalPassword = value === compareToValue ? true : false;
        if (equalPassword) {
          finalMessage = i18next.t('MSG_112');
        } else {
          isValid = validateNewPassword(value);
          finalMessage = !isValid && i18next.t('MSG_110');
        }
      }

      if (type === 'EXACT_VALUE') {
        isValid = value === compareToValue ? true : false;
        finalMessage = !isValid && i18next.t('MSG_109');
      }

      if (type === 'VALID_PASSWORD') {
        isValid = validatePassword(equalPass);
        if (isValid) {
          finalMessage = isValid && i18next.t('MSG_012');
        }
      }

      if (type === 'RFCMORAL') {
        isValid = validateRFCMoral(value);
        if (!isValid) {
          finalMessage = i18next.t('MSG_031_B');
        }
      }
      if (type === 'NUMBER') {
        isValid = validateNumber(value);
        if (!isValid) {
          finalMessage = `El campo ${fieldName} no es un número válido.`;
        }
      }

      if (type === 'AO') {
        isValid = validateNumber(value);
      }

      if (type === 'CLABE') {
        isValid = validateClabe(value);
        if (!isValid) {
          finalMessage = `Este campo debe de tener 16 o 18 dígitos `;
        }
      }

      if (!isValid) {
        if (equalPass !== undefined && equalPass === false) {
          cb();
        } else {
          cb(finalMessage);
        }
      } else {
        cb();
      }
    } catch (err) {
      cb(err);
    }
  } else {
    cb();
  }
};

export const setInvalidRFCFormat = (type, fieldName, value, cb) => {
  let isValid = false;
  let valLength = value.length;
  let finalMessage = `El campo ${fieldName} no tiene un formato válido`;
  if (typeof value !== 'undefined' && value !== '' && valLength >= 12) {
    try {
      if (type === 'both') {
        isValid = validateRFC(value) || validateRFCMoral(value);

        if (!isValid) {
          if (valLength === 12) {
            finalMessage = i18next.t('MSG_031_B');
          } else {
            finalMessage = i18next.t('MSG_031_A');
          }
        }
      }
      if (type === 'personal') {
        isValid = validateRFCPersonal(value);
        if (!isValid) {
          finalMessage = i18next.t('MSG_031_A');
        }
      }
      if (type === 'moral') {
        isValid = validateRFCMoral(value);
        if (!isValid) {
          finalMessage = i18next.t('MSG_031_B');
        }
      }

      if (!isValid) {
        cb(finalMessage);
      } else {
        cb();
      }
    } catch (err) {
      cb(err);
    }
  } else {
    cb();
  }
};

export const validMinAmount = (message, value, min, cb) => {
  if (typeof value !== 'undefined' && value !== '') {
    if (value < min) {
      cb(message);
    } else {
      cb();
    }
  } else {
    cb();
  }
};

export const validUrl = (message, value, cb) => {
  if (typeof value !== 'undefined' && value !== '') {
    const urlRegex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/i;
    if (!urlRegex.test(value)) {
      cb(message);
    } else {
      cb();
    }
  } else {
    cb();
  }
};

export const setLowerCase = value => {
  if (typeof value !== 'undefined' && value !== null) {
    return value.toLowerCase();
  }
};

export const setUpperCase = value => {
  if (typeof value !== 'undefined' && value !== null) {
    return value.toUpperCase();
  }
};

export const setCapitalLetter = string => {
  if (typeof string !== 'undefined' && string !== null) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const setFormatMarkets = string => {
  let r = string?.toLowerCase();
  r = r?.replace(new RegExp(/[àáâãäå]/g), 'a');
  r = r?.replace(new RegExp(/[èéêë]/g), 'e');
  r = r?.replace(new RegExp(/[ìíîï]/g), 'i');
  r = r?.replace(new RegExp(/[òóôõö]/g), 'o');
  r = r?.replace(new RegExp(/[ùúûü]/g), 'u');
  r = r?.replace(new RegExp(/['´´]/g), '');

  return r?.toUpperCase();
};

export const removeAccent = string => {
  let r = string?.toLowerCase();
  r = r?.replaceAll(new RegExp(/[àáâãäå]/g), 'a');
  r = r?.replaceAll(new RegExp(/[èéêë]/g), 'e');
  r = r?.replaceAll(new RegExp(/[ìíîï]/g), 'i');
  r = r?.replaceAll(new RegExp(/[òóôõö]/g), 'o');
  r = r?.replaceAll(new RegExp(/[ùúûü]/g), 'u');
  r = r?.replaceAll(new RegExp(/['´´]/g), '');

  return r;
};

export const setMaxLenghtNumber = (value, maxLength, notZeroBegin) => {
  if (typeof value !== 'undefined' && value !== null) {
    let valueToString = value.toString();
    const notZero = typeof notZeroBegin !== 'undefined' ? notZeroBegin : false;
    if (notZero) {
      if (valueToString.startsWith('0') && valueToString.length > 1) {
        valueToString = valueToString.substring(1, maxLength);
      }
    }
    return valueToString.substring(0, maxLength);
  }
};

// export const setMaskPhoneNumber = number => {
//   if (typeof number !== "undefined" && number !== null) {
//     const exp = new RegExp(/(\d{3})(\d{3})(\d{4})/, "g");
//     var maskNumber = number.replace(/\D/g, "").match(exp);
//     return `${maskNumber[1]}-${maskNumber[2]}-${maskNumber[3]}`;
//   }
// };

export const completePhoneNumber = (prefix, phoneNumber) => {
  if (typeof phoneNumber !== 'undefined' && phoneNumber !== '') {
    return `+52${phoneNumber}`;
  } else {
    return '';
  }
};

export const expToFormatNumberPrice = value => {
  let format = '';
  const exp = new RegExp(/(\d)(?=(\d\d\d)+(?!\d))/gi);
  if (typeof value !== 'undefined' && value !== null) {
    format = `$ ${value}`.replace(exp, '$1,');
  }
  return format;
};

export const expToFormatPercentage = value => {
  if (typeof value !== 'undefined' && value !== null) {
    return `${value} %`;
  } else {
    return '';
  }
};

/**
 * validates the list of options of the checks,
 * if all the options are selected, the item of all is selected
 */
export const validationsAllOption = (catalogType, form, props, setWithAll, withAll, options, setSelectedKeys = null) => {
  if (catalogType == 'checkbox') {
    const optionsSelected = form.getFieldValue(props.inputKey) || [];
    let optionsHaveAll =  false;
    if(options?.filter){
      const length = options?.findIndex(opt => opt.id === '*****')
      length >= 0 ? optionsHaveAll = true : optionsHaveAll = false;
    }
    // Check all boxes if All is checked
    if (optionsSelected.includes('*****') && !withAll && Array.isArray(options)) {
      form.setFieldsValue({ [props.inputKey]: options?.map(opt => opt.id) });
      setSelectedKeys && setSelectedKeys([options.map(opt => opt.id)]);
      setWithAll(true);
    } else if (!optionsSelected.includes('*****') && withAll) {
      // Uncheck if All is Unchecked
        form.setFieldsValue({ [props.inputKey]: [] });
        setSelectedKeys && setSelectedKeys([]);
        setWithAll(false);
    } else if (optionsSelected.includes('*****') && optionsSelected.length < options?.length) {
      // Uncheck TODOS if is checked and is unchecked one option.
      form.setFieldsValue(optionsSelected.shift());
      setSelectedKeys && setSelectedKeys([optionsSelected]);
      setWithAll(false);
    }else if(optionsHaveAll && optionsSelected.length === (options?.length -1 ) && !optionsSelected.includes('*****')){
        form.setFieldsValue({ [props.inputKey]: options?.map(opt => opt.id) });
        setSelectedKeys && setSelectedKeys([options.map(opt => opt.id)]);
        setWithAll(true);
    }
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidGuid = guid => {
  if (guid) {
    const re = new RegExp(
      '^[{(]?[0-9A-Fa-f]{8}[-]?([0-9A-Fa-f]{4}[-]?){3}[0-9A-Fa-f]{12}[)}]?$'
    );
    return re.test(guid);
  }
  return false;
};