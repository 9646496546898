export const MSG_001 = 'Operación exitosa';
export const MSG_002 = 'Error en la operación';
export const MSG_005 = 'Los archivos relacionados al caso no pueden ser mayores a 10 MB.';
export const MSG_006_1 = 'Los archivos deben pertenecer a los formatos PDF, JPG o PNG.';
export const MSG_012 = 'La contraseña ingresada es incorrecta. Ingrese una contraseña correcta.';
export const MSG_018_122 = '¿Está seguro que desea cerrar el expediente del caso? ';

export const MSG_018 = '¿Está seguro que desea ';
export const MSG_020 =
  'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.';

export const MSG_023 =
  'Los archivos correspondientes a las fotografías asociadas al caso deben pertenecer al formato JPG o PNG y tener un tamaño máximo de 10 MB.';
export const MSG_024 = 'El número máximo de fotografías que se permiten seleccionar son 15';
export const MSG_025 =
  'Ha realizado el registro de su cuenta en el sistema AUTOonline exitosamente, usted recibirá un correo electrónico una vez que la información de su registro sea validada';
export const MSG_030 =
  'El campo CURP debe constar de 18 caracteres,  por ejemplo: RMVG900515MDFRVC05';
export const MSG_031_B =
  'El campo RFC debe tener la siguiente estructura (12 caracteres).Ejemplo: BIA821120AD0';
export const MSG_031_A =
  'El campo RFC debe tener la siguiente estructura (13 caracteres).Ejemplo: RMVG900515BK1';

export const MSG_064 = 'No hay fotografías para mostrar en esta sección.';
export const MSG_065 = 'El archivo debe tener un formato xlsx y tener un tamaño máximo de 10 MB';
export const MSG_069 = (selectedCases, biddedCases) => {
  return `Usted seleccionó  ${selectedCases} casos para eliminar de la publicación, de los cuales ${biddedCases} tienen ofertas de compradores asociadas, ¿está seguro que desea eliminarlo de la publicación`;
};
export const MSG_089 =
  'Los vehículos seleccionados serán trasladados a una misma ubicación de destino. ¿Desea continuar?';
export const MSG_090 =
  'Ya han sido marcadas 15 fotografías como favoritas, se debe desmarcar una para continuar.';
export const MSG_091 =
  'Las iniciales seleccionadas para el catálogo corresponden a un catálogo que ya se encuentra registrado en el sistema. Ingrese otras iniciales.';
export const MSG_092 =
  'Las iniciales registradas para el catálogo formarán parte del identificador de este en el sistema. Considere que estas iniciales no se podrán modificar una vez que se registre el catálogo';
export const MSG_093 =
  'El número mínimo de fotografías permitidas para el registro de entrada a inventario son 6';
export const MSG_096 =
  'El enlace al que desea acceder ya ha caducado, comuníquese con los administradores de AUTOonline para obtener un nuevo enlace para realizar su registro en el sistema: Por teléfono: (55) 300 33100 ext. 2200 y 2202 Atención de Lunes a Jueves de 9:00 a.m. a 05:00 p.m. y Viernes de 9:00 am a 02:30 pm. Por email: atencionautoonline@audatex.com';
export const MSG_097 =
  'Debe aprobar o rechazar todos los archivos correspondientes a cada tipo de pago para concluir la validación.';
export const MSG_099 =
  'No hay información de vehículos disponibles en el universo de pérdidas totales';

export const MSG_109 = 'Las contraseñas ingresadas no coinciden, favor de intentarlo nuevamente';
export const MSG_110 = 'La contraseña no cumple con las características definidas';
export const MSG_112 =
  'La nueva contraseña ingresada no puede ser igual a la contraseña actual asociada a su perfil, favor de ingresar una contraseña distinta';

export const MSG_045 =
  'Los formatos permitidos para la carga de fotografías son JPG o PNG y tener un tamaño máximo total de 10 MB.';
export const MSG_006 = 'Si este dato no existe es obligatorio que se registre un Reporte';
export const MSG_007 =
  'Si este dato no existe es obligatorio que se registre un Siniestro/Número económico';
export const MSG_120 =
  'El caso seleccionado no tiene fotografías favoritas asociadas. Recuerde que para publicar un caso este debe tener al menos una fotografía favorita asociada.';
export const MSG_121 =
  'La publicación debe contener al menos un caso de vehículo o  de diverso para mantenerse activa';
export const MSG_131 = 'No hay información de comentarios para el historial.';
export const MSG_067 = 'El monto de puja no puede ser menor a 1';
export const top_winners = 'Dentro de las 3 primeras.';
export const MSG_055 = 'Tu puja se encuentra dentro del top 3.';
export const MSG_127 = 'El caso no tuvo ofertas de compradores';
export const MSG_128 =
  'El cálculo de la indemnización no se puede completar hasta que ingrese al menos una cobertura y/o una deducción.';
export const MSG_113 =
  'Han transcurrido más de seis meses desde la última vez que actualizó su contraseña, es necesario que la cambie nuevamente.';
export const MSG_141 =
  'Recuerde que debe enviar sus comprobantes de pago a validación para que sus pagos sean autorizados por AUTOonline';
export const MSG_044 = 'El número máximo de fotografías que se permiten seleccionar son 15';
export const MSG_015 = 'Su contraseña temporal debe ser actualizada para continuar.';
export const MSG_130_1 = 'El total a indemnizar es negativo: ';
export const MSG_130_2 = 'Revise nuevamente el cálculo que realizó ';
export const MSG_152 =
  'Es necesario ingresar una fecha de inicio y fecha de fin para realizar la búsqueda por intervalo de fechas';
export const MSG_154 = 'El número máximo de archivos que se pueden cargar son 5.';
/* Generic Messages (Not from the catalog of messages) */
export const waitingMessage = 'Espere por favor';
export const noDataRecovery = 'Lo sentimos, no se encontró la información';
export const noFileRecovery = 'No hay archivos asociados al tipo de documento seleccionado';
export const managementLock = 'Bloqueo Administrativo';
export const previewFileLoaded = 'Ya ha cargado el documento: ';
export const MSG_123 =
  'Para modificar la información de ubicación del caso de vehículo es necesario que primero registre la salida del vehículo del inventario';
export const MSG_160 =
  'Las fotografías de esta sección no se visualizan porque el vehículo tiene un traslado activo. Registre la salida del vehículo en el sistema.';
export const MSG_161 = 'El nombre del archivo debe ser menor o igual a 120 caracteres.';
export const MSG_162 =
  'En el sistema ya existe un caso de vehículo registrado con la misma serie visual';
export const MSG_163 =
  'En el sistema ya existe un traslado en proceso asociado a un caso de vehículo';
export const MSG_164 =
  'El RFC ingresado corresponde a una cuenta de usuario de perfil Comprador existente. Ingrese un nuevo RFC.';
export const MSG_166 =
  'Para realizar el filtrado de información por fechas es necesario que ingrese un valor en el campo "Tipo de fecha" y un intervalo de fechas.';
export const MSG_167 =
  'El expediente del caso no se puede cerrar hasta que los traslados activos asociados se finalicen o cancelen';
export const MSG_168 =
  'El registro de la salida no se puede realizar hasta que los traslados activos asociados se finalicen o cancelen';
export const MSG_169 =
  '¿Está seguro que desea modificar el motivo de salida asociado al registro de la salida? Al realizar esta acción se asignará de forma automática el estatus "Pagado" al caso de vehículo.';
export const failGetRepory =
  'La consulta de reporte por ingresar a inventario no se pudo realizar, inténtelo de nuevo más tarde';
export const MSG_076 =
  'Omisión de un campo en el intervalo de fechas. Debe ser seleccionada una fecha de inicio y fecha final';
export const MSG_005_115 =
  'El número máximo de archivos que se pueden cargar por cada tipo de documento es 15.';
