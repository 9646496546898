import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import translationEN from '../translations/enUS.json';
import translationPT from '../translations/ptBR.json';
import translationES from '../translations/esMX.json';

const resources = {
    es: {
        translation: translationES,
    },
    en: {
        translation: translationEN,
    },
    pt: {
        translation: translationPT,
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        interpolation: { escapeValue: false },  // React already does escaping
        resources,
        lng: 'es',
        fallbackLng: 'en',
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        // react-i18next options
        react: {
            // wait: true,
            useSuspense: true
        },
    });

getIP();

async function getIP() {
    const response = await axios.get('https://www.cloudflare.com/cdn-cgi/trace');
    var lines = response.data.split('\n');
    var keyValue;
    const user = localStorage.getItem('user');
    if (user) {
        var parsedUser = JSON.parse(user);
        if (parsedUser.languageAbbreviation && i18n.language != parsedUser.languageAbbreviation) {
            i18n.changeLanguage(parsedUser.languageAbbreviation);
        }
    } else {
        for (var index = 0; index < lines.length; index++) {
            const line = lines[index];
            keyValue = line.split('=');
            if (keyValue[0] === 'loc') {
                let culture = getCulture(keyValue[1]);
                //culture = "en";
                console.log(culture);
                i18n.changeLanguage(culture);
            }

        }
    }
}

function getCulture(countryCode) {
    var es = [
        'AR',
        'BO',
        'CL',
        'CO',
        'CR',
        'DO',
        'EC',
        'SV',
        'GT',
        'HN',
        'MX',
        'NI',
        'PA',
        'PY',
        'PE',
        'PR',
        'ES',
        'UY',
        'VE',
    ];
    var pt = ['BR', 'PT'];
    if (es.indexOf(countryCode) > -1) return 'es';
    if (pt.indexOf(countryCode) > -1) return 'pt';

    return 'en';
}

export default i18n;
