export const iconsMenu = {
  '1': 'pie-chart',
  '2': 'user-add',
  '3': 'car',
  '4': 'apartment',
  '5': 'transfers',
  '6': 'publication',
  '7': 'awardHandCar',
  '8': 'dollar'
};
