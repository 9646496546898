/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React from 'react';

import { notification } from 'antd';
import { setSession, requestLogOut } from '../redux/actions/authActions';
import { userSetData } from '../redux/actions/userActions';
import autoStore from '../redux/store';
import instance from '../services/request';
import moment from 'moment';
import { dateFormat, dateFormatISO, hourFormat } from './constants';

// const { Title } = Typography;

export const configError = {
  className: 'notification-autoonline notification-error',
  duration: 8
};

export const configWarning = {
  className: 'notification-autoonline notification-warning',
  duration: 8
};

export const configSuccess = {
  duration: 3,
  className: 'notification-autoonline notification-success'
};

export const configInfo = {
  className: 'notification-autoonline notification-info',
  duration: 4
};

export const initialConfigurations = () => {
  tokenExpired();
  notification.config({
    placement: 'bottomRight',
    bottom: 20,
    duration: 0
  });

  const doWarn = window.console.warn;
  window.console.warn = (...args) => {
    if (
      typeof args[0] !== 'string' ||
      !args[0].startsWith('Warning: componentWillReceiveProps has been renamed')
    ) {
      doWarn(...args);
    }
  };

  // function closingCode() {
  //   Modal.confirm({
  //     title: (
  //       <Title level={4}>
  //         ¿Está seguro que desea <strong>cerrar sesión</strong>?
  //       </Title>
  //     ),
  //     okText: 'Aceptar',
  //     cancelText: 'Cancelar',
  //     onOk() {
  //       autoStore.dispatch(logOutUser());
  //     }
  //   });
  //   return null;
  // }

  // window.addEventListener(
  //   'beforeunload',
  //   function(e) {
  //     e.preventDefault();
  //     return "¿Estás seguro de querer cerrrar el navegador?";

  //     // e.returnValue = '';
  //   },
  //   false
  // );
};

// export const testStore = () => {
//   return autoStore;
// };
//getting from local machine
export const setTimeZoneToString = value => {
  return moment(value)
    .utcOffset(-360)
    .toISOString(true);
};

export const cleanLocalStorage = () => {
  Object.keys(window.localStorage).forEach(function (key) {
    if (key !== 'isModeTable') {
      window.localStorage.removeItem(key);
    }
  });
};

export const tokenExpired = () => {
  if (localStorage.tkn && localStorage.user) {
    const user = JSON.parse(localStorage.user);
    let current = currentTimeInMiliseconds();
    const expiresAt = user.expiresAt;
    // console.log('current universal time :>> ', current);
    // console.log('new Date() :>> ', new Date());
    // eslint-disable-next-line no-console
    console.log('will be expiresAt =>', new Date(expiresAt));
    if (expiresAt < current) {
      cleanLocalStorage();
      notification.info({
        message: `Ha permanecido mucho tiempo inactivo, Tu sesion a expirado.`,
        ...configInfo
      });
      instance.defaults.headers.common['Authorization'] = null;
      autoStore.dispatch(requestLogOut());
      window.location.replace('/');
    } else {
      instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.tkn}`;
      autoStore.dispatch(setSession());

      autoStore.dispatch(userSetData(JSON.parse(localStorage.user)));
    }
  }
};

// export const notNetwork = error => {
//   if (typeof error === 'object') {
//     if (typeof error.message !== 'undefined' && error.message.includes('Network Error')) {
//       notification.error({
//         message: 'Sin conexión',
//         ...configError
//       });
//     } else {
//       notification.error({
//         message: 'Problemas de comunicación',
//         ...configError
//       });
//     }
//   }
// };

export const currentTimeInMiliseconds = () => {
  let a = new Date().getTime();
  return a;
};

export const serviceMassiveUsers = (id, text) => {
  let path = '';
  switch (id) {
    case 1:
      path = 'Admins';
      break;
    case 2:
      path = 'InsCarriers';
      break;
    case 3:
      path = 'InsPerson';
      break;
    case 4:
      path = 'Providers';
      break;
    case 5:
      path = 'Buyers';
      if (text === 'Comprador (Persona Moral)') {
        path = 'Buyers/BuyerCompany';
      }
      break;
    default:
      break;
  }
  return path;
};

export const assignNameRolProfile = (id, text) => {
  let path = '';
  switch (id) {
    case 1:
    case 6:
      path = 'admin';
      break;
    case 2:
      path = 'insuranceCarrier';
      break;
    case 3:
      path = 'insured';
      break;
    case 4:
      path = 'provider';
      break;
    case 5:
      path = 'buyer';
      if (text === 'Comprador (Persona Moral)') {
        path = 'Buyers/BuyerCompany';
      }
      break;
    default:
      break;
  }
  return path;
};

export const setFormatDateTime = (dateTime, index) => {
  if (typeof dateTime !== 'undefined' && dateTime) {
    let d = dateTime.split('T');
    if (index === 1) {
      let a = d[index].split(':');
      return `${a[0]}:${a[1]}`;
    }
    const preResult = d[index];
    return moment(preResult).format(dateFormat);
  } else {
    return '- - : - - ';
  }
};

export const normalizeDataSearch = val => {
  if (typeof val === 'string' && val !== '') {
    val = val.trim();
    return val.toLowerCase();
  }
};

export const normalizeDataCase = caseId => {
  if (typeof caseId !== 'undefined') {
    let currentLength = caseId.toString().length;
    let pad = '';
    for (let index = 8; index > currentLength; index--) {
      pad += '0';
    }
    pad = `${pad}${caseId}`;
    return pad;
  }
};

export function setIconFile(ext) {
  //ZIP       ->  <Icon type="file-zip" />        ZIP
  //UNDEFINED ->  <Icon type="file-unknown" />    ?
  //WORD      ->  <Icon type="file-word" />       DOC,DOCX
  //EXCEL     ->  <Icon type="file-excel" />      XLS,XLSX
  //IMAGE     ->  <Icon type="file-image" />      PNG,JPG,GIF
  //PDF       ->  <Icon type="file-pdf" />        PDF
  if (typeof ext !== 'undefined') {
    if (ext === 'jpg' || ext === 'png' || ext === 'jpeg') {
      ext = 'image';
    }
    switch (ext) {
      case 'image': //aprobado
        return 'file-image';
      case 'pdf': //rechazado
        return 'file-pdf';
      case 'xlsx':
        return 'file-excel';
      default:
        return 'file-unknown';
    }
  }
}

export const getExtFile = nameFile => {
  if (typeof nameFile !== 'undefined') {
    let normalizeName = nameFile.toLowerCase();
    let splitName = normalizeName.split('.');
    if (splitName.length && typeof splitName[splitName.length - 1] !== 'undefined') {
      return splitName[splitName.length - 1];
    }
  }
};

export const base64toBlob = (base64Data, contentType) => {
  contentType = typeof contentType !== 'undefined' ? contentType : 'application/pdf';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);
    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    // eslint-disable-next-line no-undef
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const filterArrayByIndexValue = (array, indexFilter, filterValue) => {
  return array.filter(d => d[indexFilter] === filterValue);
};

export const filterArrayByIndex = (array, filterIndex) => {
  return array
    .map(item => item[filterIndex])
    .filter((value, index, self) => self.indexOf(value) === index);
};

export const prepareDataDocumentsList = (documentsFiltered, allDocuments, field) => {
  return documentsFiltered.map(s => {
    let filtered = allDocuments.filter(sub => sub[field] === s);
    return { name: `${s}`, data: [...filtered] };
  });
};

// export const stringToFloat = string => {
//   if (string !== '') {
//     string = string.toString();
//     //string = string.replace(/ /g, "");
//     string = string.replace(/\$/g, '');
//     string = string.replace(/,/g, '');
//     let sanitize = string;
//     sanitize = Number.parseFloat(sanitize).toFixed(2);
//     return parseFloat(sanitize);
//   }
// };

// export const formatNumber = number => {
//   // let c = number.toLocaleString(undefined, {
//   //   minimumFractionDigits: 2,
//   //   maximumFractionDigits: 2
//   // });

//   return new Intl.NumberFormat('es-MX', {
//     style: 'currency',
//     currency: 'MXN',
//     minimumFractionDigits: 2
//   }).format(number);
// };

export const objectHasProperty = (obj, key) => {
  if (obj && typeof obj === 'object' && obj !== null) {
    return Object.prototype.hasOwnProperty.call(obj, key);
  }
  return false;
};

export const beautyJSON = data => {
  return JSON.stringify(data, undefined, 2);
};

export const orderArrayObjects = (property, desc) => {
  const orderUp = desc ? false : true;
  return function (obj1, obj2) {
    if (obj1[property]) {
      if (orderUp) {
        return obj1[property] > obj2[property] ? 1 : obj1[property] < obj2[property] ? -1 : 0;
      } else {
        return obj1[property] < obj2[property] ? 1 : obj1[property] > obj2[property] ? -1 : 0;
      }
    } else {
      return 0;
    }
  };
};

export const getPrefixPhoneNumber = phoneNumber => {
  if (phoneNumber.length === 10) {
    return phoneNumber;
  }
  return phoneNumber.substring(3, phoneNumber.length);
};

export const cleanPhoneNumber = phoneNumber => {
  let clean = '';
  if (phoneNumber !== '') {
    clean = phoneNumber.replace(/ /g, '');
    clean = clean.replace(/-/g, '');
    if (clean.length === 13) {
      return getPrefixPhoneNumber(clean);
    } else {
      if (clean.length > 10) {
        clean = clean.substring(0, 10);
      }

      return clean;
    }
  }
};

export const setDataToLink = (Link, path, objectState, label) => {
  return (
    <Link
      to={{
        pathname: path,
        state: objectState
      }}
    >
      {label}
    </Link>
  );
};

export const getDataOnSplitURL = (url, index) => {
  const splitURL = url.split('/');
  if (splitURL[index]) {
    const data = splitURL[index];
    return data;
  } else {
    console.warn('[AutoOnline] No existe indíce de dónde obtener el dato en la URL');
    return false;
  }
};

export const existObject = object => {
  return object && typeof object === 'object';
};

export const generateUUID = () => {
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  return uuid;
};

export const emptyUUID = '00000000-0000-0000-0000-000000000000';

export const responseOK = response => {
  return typeof response !== 'undefined' && response.status === 200;
};

export const excludeFromObject = (obj, key) => {
  // eslint-disable-next-line no-unused-vars
  const { [key]: _exclude, ...rest } = obj;
  return rest;
};

export const retry = (fn, retriesLeft = 2, interval = 1000) => {
  // eslint-disable-next-line no-undef
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        if (navigator.onLine)
          setTimeout(() => {
            console.warn('retriesLeft :>> ', retriesLeft);
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded');
              reject(error);
              return true;
            }
            // Passing on "reject" is the important part

            retry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        else reject(error);
      });
  });
};

export const buildURLImage = (picsToken, fileId, path, thumbail =  true) => {
  if (typeof path === 'undefined') {
    //console.log('window.location.hostname', window.location.hostname);
    if (window.location.hostname === 'https://autoonlinefeuat.stefaninidev.tk/') {
      path = 'FilesService/Api/v1/Pics';
    } else if (window.location.hostname === 'http://localhost:3000/') {
      path = 'Files/Api/v1/Pics';
    } else {
      path = 'Files/Api/v1/Pics';
    }
  }
  // eslint-disable-next-line no-undef
  const url = `${process.env.REACT_APP_API_GATEWAY}${path}/${picsToken}/${fileId}?thumbail=${thumbail ?  'true' : 'false'}`;
  //https://autoonlinebedev.stefaninidev.tk:8443/
  //return `${window.location.hostname}/FilesService/Api/v1/Pics/${picsToken}/${fileId}`;
  return url;
};

export const getItemFromLocal = key => {
  try {
    return localStorage.getItem(key);
  } catch (_error) {
    // eslint-disable-next-line no-console
    console.log(_error);
  }
};

export const auxDisabledCheckBox = array => {
  let aux = [];
  if (array && array.length > 0) {
    array.map(a => {
      aux = [...aux, { ...a, disabled: !a.enableChange }];
    });
  }
};

export const getStringDateFromObjectMoment = (date, type) => {
  if (date) {
    if (type === 'ISO') {
      return moment(date).format(dateFormatISO);
    } else {
      return moment(date).format(dateFormat);
    }
  }
};

export const getStringHourFromObjectMoment = hour => {
  if (hour) {
    return moment(hour).format(hourFormat);
  }
};

export const getCurrentHour = () => {
  let d = new Date();
  return d.getHours();
};

export const setRange = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
// setTimeout(() => {
//   console.log('retriesLeft :>> ', retriesLeft);
//   if (retriesLeft === 1) {

//     reject(error);
//     return true;
//   }

//   try {
//     retry(fn, retriesLeft - 1, interval).then(resolve, reject);
//   } catch (e) {
//     reject(e);
//   }
// }, interval);

export const createBase64File = (base64, type) => {
  let linkSource;
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    let data = base64toBlob(base64, type);
    linkSource = URL.createObjectURL(data);
  } else {
    linkSource = `${type}${base64}#zoom=100&view=fitH`;
  }
  return linkSource;
};

export const helperFilter = (data, key, valueToFilter) => {
  if (data) {
    return data.filter(d => !valueToFilter.includes(d[key]));
  }
  return [];
};

export const dayOfTheMonth = d => {
  return (d.getDate() < 10 ? '0' : '') + d.getDate();
};

// export const getCurrentDate = () => {
//   let year = new Date().getFullYear();
//   let month = dayOfTheMonth();
//   let currentDay = new Date();
//   let day = currentDay.getDay();
//   return `${year}-${month}-${day}`;
// };

export const getToday = separator => {
  let year = new Date().getFullYear();
  separator = typeof separator !== 'undefined' ? separator : '-';
  let a = new Date();
  let day = dayOfTheMonth(a);
  let month = ('0' + (a.getMonth() + 1)).slice(-2);
  return `${day}${separator}${month}${separator}${year}`;
};

export const getExtensionFile = fileName => {
  let regex = new RegExp('[^.]+$');
  let lower = fileName.toLowerCase();
  let fileExtension = lower.match(regex);
  if (fileExtension[0]) {
    return fileExtension[0];
  }
};

export const helperOrder = photos => {
  let dataToSave = [];
  let order = 1;
  photos.map(pic => {
    const formatedPicToSave = {
      ...pic,
      order: order++
    };
    dataToSave.push(formatedPicToSave);
  });

  return dataToSave;
};

export const helperFav = (photos, fileId, setAll = false, checked = false) => {
  let withoutFind;
  const found = photos.data.find(item => item.fileId === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.fileId !== fileId);
    if(!setAll){
      if (found.favorite === false || found.asFavorite === false) {
        withoutFind = [...withoutFind, { ...found, asFavorite: true, favorite: true }];
      } else if (found.favorite === true || found.asFavorite === true) {
        withoutFind = [...withoutFind, { ...found, asFavorite: false, favorite: false }];
      }
    }else{
      withoutFind = [...withoutFind, { ...found, asFavorite: checked, favorite: checked }];
    }
  }

  if (withoutFind && withoutFind.length > 0) return withoutFind.sort(orderArrayObjects('fileId'));
};

export const helperFavAudatex = (photos, fileId) => {
  let withoutFind;
  const found = photos.data.find(item => item.fileId === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.fileId !== fileId);
    if (found.favorite === false && found.checked === false) {
      withoutFind = [...withoutFind, { ...found, favorite: true, checked: true }];
    } else if (found.favorite === true && found.checked === true) {
      withoutFind = [...withoutFind, { ...found, favorite: false }];
    } else if (found.favorite === false && found.checked === true) {
      withoutFind = [...withoutFind, { ...found, favorite: true }];
    } else if (found.favorite === true && found.checked === false) {
      withoutFind = [...withoutFind, { ...found, favorite: false }];
    }
  }
  if (withoutFind && withoutFind.length > 0) return withoutFind.sort(orderArrayObjects('fileId'));
};

export const helperCheck = (photos, fileId) => {
  let withoutFind;
  const found = photos.data.find(item => item.fileId === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.fileId !== fileId);
    if (found.checked) {
      withoutFind = [...withoutFind, { ...found, checked: false }];
    } else {
      withoutFind = [...withoutFind, { ...found, checked: true }];
    }
  }
  if (withoutFind && withoutFind.length > 0) return withoutFind.sort(orderArrayObjects('fileId'));
};

export const helper = (photos, fileId) => {
  let withoutFind;
  const found = photos.data.find(item => item.id === fileId);
  if (found) {
    withoutFind = photos.data.filter(item => item.id !== fileId);
    if (found.favorite) {
      withoutFind = [...withoutFind, { ...found, favorite: false }];
    } else {
      withoutFind = [...withoutFind, { ...found, favorite: true }];
    }
  }
  if (withoutFind) return withoutFind.sort(orderArrayObjects('order'));
};

export const renderChart = (array, message, chart) => {
  if (array) {
    return chart;
  } else {
    return message;
  }
};

export const spliceArrayinTwo = array => {
  const length = array.length;
  let m1 = array.splice(0, length / 2);
  let m2 = array.splice(0, length);
  return { m1, m2 };
};

export async function reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
  // eslint-disable-next-line no-undef
  let resized_base64 = await new Promise(resolve => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL()); // this will return base64 image results after resize
    };
  });
  return resized_base64;
}

export const calc_image_size = image => {
  let y = 1;
  if (image.endsWith('==')) {
    y = 2;
  }
  const x_size = image.length * (3 / 4) - y;
  return Math.round(x_size / 1024);
};
