import { sendNotification } from "helpers/helperNotifications";
import i18next from "i18next";
import { requestFavoriteCasePhoto } from "redux/actions/caseActions";
import { picsInventoryCheckInFav, picsInventoryFavoriteRequest } from "redux/actions/picsActions";
import { setFavoriteStatus } from "redux/sagas/pics/audatexPics/audatexPics";
import store from "redux/store";
import { isValidGuid } from "./validations";

export const setProfilesToGroup = (id, group, profiles) => {
  let pro = profiles;
  let selected = group.filter(g => g.groupId === id);
  let aux = {
    ...selected[0],
    profiles: pro
  };
  let exclude = group.filter(g => g.groupId !== id);
  let newData = {
    current: aux,
    newArray: exclude.concat(aux)
  };
  return newData;
};


export const claimValue = (claimsRole, claimsUser, value, condition) => {
  if(!(claimsRole!=undefined && claimsRole!=undefined))
  {
  return condition;
  }
  const valueFoundUser = claimsUser.find(element => element.name === value);
  const valueFoundRole = claimsRole.find(element => element.name === value);

  if (valueFoundUser != undefined) {
    return valueFoundUser.enabled;
  }

  if (valueFoundRole != undefined) {
    return valueFoundRole.enabled;
  }
  return condition;
}

export const claimValidate = (claimsRole, claimsUser, value) => {
  const valueFoundUser = claimsUser.find(element => element.name === value);
  const valueFoundRole = claimsRole.find(element => element.name === value);

  if (valueFoundUser != undefined) {
    return valueFoundUser.enabled;
  }

  if (valueFoundRole != undefined) {
    return valueFoundRole.enabled;
  }
  return true;
}

export const validateAddressProof =  (proofAddress, isRegister = false) =>{
  let isValid = false;
    if(proofAddress && proofAddress.data && proofAddress.data.length > 0){
      const filteredContentAddressProof = proofAddress.data.filter((item) => item.content != null);
      if(filteredContentAddressProof.length > 0)
        isValid = true;  
    }

    if(isRegister)
      isValid = true;

    if(!isValid)
      sendNotification('error', i18next.t('MSG_170'));
    return isValid;
}

export const handleAllFavorites = (checked, caseId, photos, imageType, setAllChecked, onSuccessFavorite, onFailFavorite,inventoryId = null ) => {
  const favoriteCount = getGlobalFavoritePics();
  if(checked && favoriteCount + photos.filter(x => !x.favorite && !x.asFavorite).length  > 15){
    sendNotification('info', i18next.t('system.max.favorite').replace('{0}', favoriteCount));
  }else{
    photos.map(p =>{
      if(imageType == "incoming"){
        if(isValidGuid(p?.fileId)){
          store.dispatch(picsInventoryFavoriteRequest({
            fileId: p.fileId,
            inventoryId,
            onFailFavorite,
            from: imageType,
            asFavorite: checked,
            onSuccess:  () => onSuccessFavorite(p, checked),
            fromAll: true,
            setAll: true
          }));
        }else{
          store.dispatch(picsInventoryCheckInFav({
            fileId: p?.fileId, onFailFavorite, asFavorite: checked, setAll: true
          }));
        }
      }else{
        store.dispatch(requestFavoriteCasePhoto({
          caseId: caseId,
          fileId: p.id,
          onSuccessFavorite: () => onSuccessFavorite(p, checked),
          onFailFavorite: onFailFavorite,
          imageType: imageType,
          asFavorite: checked,
          fromAll: true,
          setAll: true
        }));
      }
    });
    setAllChecked(checked);
  }
 }

export const getGlobalFavoritePics = () => {
    let favorites = 0;
    const actualState = store.getState();
    const casesPics = actualState.case.photos.data;
    const recolectionPics = actualState.transfers.recolectedPhotos.data
    const deliveryPics = actualState.transfers.deliveredPhotos.data
    const incomingPics = actualState.inventoryPhotos.incoming.data;
    const checkOut = actualState.inventoryPhotos.checkout.data;

    if(casesPics){
      casesPics.map(p => {
        if(p.favorite){
          favorites++;
        }
      });
    }
   
    if(recolectionPics){
      recolectionPics.map(p => {
        if(p.favorite){
          favorites++;
        }
      });
    }

    if(deliveryPics){
      deliveryPics.map(p => {
        if(p.favorite){
          favorites++;
        }
      });
    }
    
    if(incomingPics){
      incomingPics.map(p => {
        if(p.asFavorite){
          favorites++;
        }
      });
    }

    if(checkOut){
      checkOut.map(p => {
        if(p.asFavorite){
          favorites++;
        }
      });
    }

    return favorites;
}
//returning direct base64 from blob
export const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = function() {
            resolve(reader.result.split(',')[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export const base64ToBlob = (base64String, contentType) => {  
  const byteCharacters = atob(base64String);  
  const byteArrays = [];  
  
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {  
    const slice = byteCharacters.slice(offset, offset + 512);  
    const byteNumbers = new Array(slice.length);  
  
    for (let i = 0; i < slice.length; i++) {  
      byteNumbers[i] = slice.charCodeAt(i);  
    }  
  
    const byteArray = new Uint8Array(byteNumbers);  
    byteArrays.push(byteArray);  
  }  
  
  const blob = new Blob(byteArrays, { type: contentType });  
  return blob;  
}

export const createArrOfImagesBlob = (stateImages, caseId) =>{
  let arrayOfPics = [];

  const pics = stateImages.filter(pic => pic.caseId === caseId);

  if(pics){
    pics.forEach(element => {
      const base64Thumbnail = element.blob.thumbnail;
      const base64Full = element.blob.fullScreen ?  element.blob.fullScreen : base64Thumbnail;
      const originalUrl = `data:image/jpeg;base64,${base64Full}`;
      const thumbnailUrl = `data:image/jpeg;base64,${base64Thumbnail}`;
      arrayOfPics = [
        ...arrayOfPics,
        {
          original: originalUrl,
          thumbnail: thumbnailUrl,
          originalClass: ''
        }
      ];
    }
    );
  } 
  return arrayOfPics;
};

export const createInvidualImageUrl = (stateImages, caseId, fileId) =>{
  let picUrl;
  const pic = stateImages.find(pic => pic.caseId === caseId && pic.id === fileId);
  if(pic){
    picUrl = pic.blob.fullScreen ? `data:image/jpeg;base64,${pic.blob.fullScreen}`  : `data:image/jpeg;base64,${pic.blob.thumbnail}`;
  }
  return picUrl;
};

